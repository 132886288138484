import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { ConceptStatus } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      default: 'company'
    },
    label: {
      type: String,
      default: ''
    },
    useAddOneConcept: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var conceptSearchModel = ref(props.value);
    var valueEntered = ref(props.value !== ''); // Don't render the alert if there is already a concept.
    var conceptCombobox = ref(null);
    var submitInput = function submitInput(model) {
      if (typeof model === 'string') {
        // this is when user tries to create a new concept
        model = {
          label: model
        };
      }
      if (model !== null) {
        emit('input', model);
        if (!props.useAddOneConcept) {
          conceptSearchModel.value = '';
        } else {
          valueEntered.value = true;
        }
      }
    };
    var statusToColor = function statusToColor(status) {
      if ([ConceptStatus.Verified, ConceptStatus.Autoverified].includes(status)) return 'success';else return 'warning';
    };
    var statusText = function statusText(status) {
      if ([ConceptStatus.Verified, ConceptStatus.Autoverified].includes(status)) return i18n.t('verifiedUnverified.verified');else return i18n.t('verifiedUnverified.unverified');
    };
    return {
      submitInput: submitInput,
      conceptCombobox: conceptCombobox,
      conceptSearchModel: conceptSearchModel,
      valueEntered: valueEntered,
      statusToColor: statusToColor,
      statusText: statusText,
      concepts: [],
      conceptSearch: ''
    };
  }
});